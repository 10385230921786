import Axios from 'axios';



const state = {
  content: '',
};

const getters = {
  GET_CONTENT: state => state.content,
};

const mutations = {
  SET_CONTENT: (state, payload) => {
    state.content = payload.data;
  },
  CLEAR_CONTENT: state => state.content = '',
};

const actions = {
  GET_CONTENT_FROM_SERVER: async (context, payload) => {
    let { data } = await Axios.get(`/api/content/${payload.type}/${payload.id}`);
    context.commit('SET_CONTENT', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
