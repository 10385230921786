import Axios from 'axios';
import i18n from "../i18n";

const state = {
  data: [],
  links: {},
  meta: {},
  region: '',
};

const getters = {
  GET_CITIES: state => state,
  GET_REGION: state => state.region,
};

const mutations = {
  SET_CITIES: (state, payload) => {
    state.data = payload.data.filter(item => item.slug !== 'tomsk-region').sort((a, b) => a.name.localeCompare(b.name));
    state.region = payload.data.find(item => item.slug === 'tomsk-region');
  },
};

const actions = {
  GET_CITIES_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/cities?count=100500&lang=${i18n.locale}`);
    context.commit('SET_CITIES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
