import moment from 'moment';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import YandexMap from 'vue-yandex-maps';
import SocialSharing from 'vue-social-sharing';
import Vue2TouchEvents from 'vue2-touch-events';
import {
  LMap, LMarker, LPolyline, LTileLayer,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import Vue from 'vue';
import Axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import i18n from './i18n';

Vue.component('AboutAgency', () => import('./components/AboutAgency/index.vue'));
Vue.component('AboutRegion', () => import('./components/AboutRegion/index.vue'));
Vue.component('AccountContent', () => import('./components/AccountContent/index.vue'));
Vue.component('AccountDashboard', () => import('./components/AccountDashboard/index.vue'));
Vue.component('AccountDashboardAgency', () => import('./components/AccountDashboardAgency/index.vue'));
Vue.component('AccountMenu', () => import('./components/AccountMenu/index.vue'));
Vue.component('AddFile', () => import('./components/AddFile/index.vue'));
Vue.component('AgenciesGuidesAgencies', () => import('./components/AgenciesGuidesAgencies/index.vue'));
Vue.component('AgenciesGuidesAgency', () => import('./components/AgenciesGuidesAgency/index.vue'));
Vue.component('AgencyCardEditor', () => import('./components/AgencyCardEditor/index.vue'));
Vue.component('Anchor', () => import('./components/Anchor/index.vue'));
Vue.component('AudioPlayer', () => import('./components/AudioPlayer/index.vue'));
Vue.component('Badge', () => import('./components/Badge/index.vue'));
Vue.component('BannerInteractiveCard', () => import('./components/BannerInteractiveCard/index.vue'));
Vue.component('Billboard', () => import('./components/Billboard/index.vue'));
Vue.component('BreadCrumbs', () => import('./components/BreadCrumbs/index.vue'));
Vue.component('Button', () => import('./components/Button/index.vue'));
Vue.component('Column', () => import('./components/Column/index.vue'));
Vue.component('Divider', () => import('./components/Divider/index.vue'));
Vue.component('EventCard', () => import('./components/EventCard/index.vue'));
Vue.component('ExcursionCard', () => import('./components/ExcursionCard/index.vue'));
Vue.component('ExcursionCardEditor', () => import('./components/ExcursionCardEditor/index.vue'));
Vue.component('Favorite', () => import('./components/Favorite/index.vue'));
Vue.component('Footer', () => import('./components/Footer/index.vue'));
Vue.component('FormationPortal', () => import('./components/FormationPortal/index.vue'));
Vue.component('Gallery', () => import('./components/Gallery/index.vue'));
Vue.component('GuestReviews', () => import('./components/GuestReviews/index.vue'));
Vue.component('GuideAccordion', () => import('./components/GuideAccordion/index.vue'));
Vue.component('GuideAccordionTab', () => import('./components/GuideAccordion/GuideAccordionTab.vue'));
Vue.component('Header', () => import('./components/Header/index.vue'));
Vue.component('HeroSlider', () => import('./components/HeroSlider/index.vue'));
Vue.component('HeroTop', () => import('./components/HeroTop/index.vue'));
Vue.component('HeroTopSOS', () => import('./components/HeroTopSOS/index.vue'));
Vue.component('Icon', () => import('./components/Icon/index.vue'));
Vue.component('ImageComponent', () => import('./components/ImageComponent/index.vue'));
Vue.component('Info', () => import('./components/Info/index.vue'));
Vue.component('InputComponent', () => import('./components/InputComponent/index.vue'));
Vue.component('InterestingFact', () => import('./components/InterestingFact/index.vue'));
Vue.component('LocaleChanger', () => import('./components/LocaleChanger/index.vue'));
Vue.component('LinkBox', () => import('./components/LinkBox/index.vue'));
Vue.component('List', () => import('./components/List/index.vue'));
Vue.component('Loader', () => import('./components/Loader/index.vue'));
Vue.component('NewsCard', () => import('./components/NewsCard/index.vue'));
Vue.component('OnMap', () => import('./components/OnMap/index.vue'));
Vue.component('PageNavigation', () => import('./components/PageNavigation/index.vue'));
Vue.component('PlaceCard', () => import('./components/PlaceCard/index.vue'));
Vue.component('PlaceCardOnMap', () => import('./components/PlaceCardOnMap/index.vue'));
Vue.component('PopularRouteEdit', () => import('./components/PopularRouteEdit/index.vue'));
Vue.component('Popup', () => import('./components/Popup/index.vue'));
Vue.component('PopupChangeLang', () => import('./components/Popup/PopupChangeLang.vue'));
Vue.component('PopupAddAgencies', () => import('./components/Popup/PopupAddAgencies.vue'));
Vue.component('PopupAddComment', () => import('./components/Popup/PopupAddComment.vue'));
Vue.component('PopupAddExcursion', () => import('./components/Popup/PopupAddExcursion.vue'));
Vue.component('PopupAddFact', () => import('./components/Popup/PopupAddFact.vue'));
Vue.component('PopupAddGuide', () => import('./components/Popup/PopupAddGuide.vue'));
Vue.component('PopupAddObject', () => import('./components/Popup/PopupAddObject.vue'));
Vue.component('PopupAddSomething', () => import('./components/Popup/PopupAddSomething.vue'));
Vue.component('PopupAddSomething', () => import('./components/Popup/PopupAddSomething.vue'));
Vue.component('PopupAudioGuide', () => import('./components/Popup/PopupAudioGuide.vue'));
Vue.component('PopupAudioTrips', () => import('./components/Popup/PopupAudioTrips.vue'));
Vue.component('PopupCommentOnModeration', () => import('./components/Popup/PopupCommentOnModeration.vue'));
Vue.component('PopupGuide', () => import('./components/Popup/PopupGuide.vue'));
Vue.component('PopupImage', () => import('./components/Popup/PopupImage.vue'));
Vue.component('PopupStories', () => import('./components/Popup/PopupStories.vue'));
Vue.component('PopupSuccessfulAddExcursion', () => import('./components/Popup/PopupSuccessfulAddExcursion.vue'));
Vue.component('PopupSuccessfulAddFact', () => import('./components/Popup/PopupSuccessfulAddFact.vue'));
Vue.component('PopupSuccessfulAddObject', () => import('./components/Popup/PopupSuccessfulAddObject.vue'));
Vue.component('PopupSuccessfulAddReview', () => import('./components/Popup/PopupSuccessfulAddReview.vue'));
Vue.component('PopupTermsConditions', () => import('./components/Popup/PopupTermsConditions.vue'));
Vue.component('Rating', () => import('./components/Rating/index.vue'));
Vue.component('ReasonCard', () => import('./components/ReasonCard/index.vue'));
Vue.component('ReasonsVisitArea', () => import('./components/ReasonsVisitArea/index.vue'));
Vue.component('ResponseForSearch', () => import('./components/ResponseForSearch/index.vue'));
Vue.component('ReviewsTourists', () => import('./components/ReviewsTourists/index.vue'));
Vue.component('RichStory', () => import('./components/RichStory/index.vue'));
Vue.component('Row', () => import('./components/Row/index.vue'));
Vue.component('Section', () => import('./components/Section/index.vue'));
Vue.component('SelectComponent', () => import('./components/SelectComponent/index.vue'));
Vue.component('Share', () => import('./components/Share/index.vue'));
Vue.component('Slider', () => import('./components/Slider/index.vue'));
Vue.component('Sort', () => import('./components/Sort/index.vue'));
Vue.component('Sprite', () => import('./components/Sprite/index.vue'));
Vue.component('Story', () => import('./components/Story/index.vue'));
Vue.component('Tabs', () => import('./components/Tabs/index.vue'));
Vue.component('Tag', () => import('./components/Tag/index.vue'));
Vue.component('Tags', () => import('./components/Tags/index.vue'));
Vue.component('ThematicSlider', () => import('./components/ThematicSlider/index.vue'));
Vue.component('TourCompanyMenu', () => import('./components/TourCompanyMenu/index.vue'));
Vue.component('TransportMap', () => import('./components/TransportMap/index.vue'));
Vue.component('TripCard', () => import('./components/TripCard/index.vue'));
Vue.component('TripCardOnMap', () => import('./components/TripCardOnMap/index.vue'));
Vue.component('TripPlanPoint', () => import('./components/TripPlanPoint/index.vue'));
Vue.component('UserAvatar', () => import('./components/UserAvatar/index.vue'));
Vue.component('VideoYouTube', () => import('./components/VideoYouTube/index.vue'));
Vue.component('ModifiedBillboard', () => import('./components/ModifiedComponentes/ModifiedBillboard/index.vue'));

import clickOutside from './directives/click-outside';
import computed from './computed';
import methods from './methods';
import scroll from './directives/scroll';

Vue.prototype.$moment = moment;
// Vue.prototype.$moment.locale(i18n.locale);
Vue.prototype.$http = Axios;

const settings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

Vue.use(YandexMap, settings);
Vue.use(Vue2TouchEvents);
Vue.use(SocialSharing);

Vue.component('YandexMap', YandexMap);
Vue.component('DateTimePicker', DateTimePicker);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polyline', LPolyline);

Vue.filter('countCharsInString', (string, count) => {
  if (!string) return '';
  if (string.length > count) {
    return `${string.slice(0, count)}...`;
  }
  return string;
});

Vue.directive('click-outside', clickOutside);
Vue.directive('scroll', scroll);

Vue.mixin({
  methods,
  computed,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
