import Axios from 'axios';

const state = {
  data: null
};
const getters = {
  GET_WEATHER: state => state,
};
const mutations = {
  SET_WEATHER: (state, payload) => state.data = payload,
};
const actions = {
  GET_WEATHER_FROM_SERVER: async ({ commit }) => {
    const appid = process.env.VUE_APP_DEFAULTH_WEATHER_APP_ID;
    const latitude = process.env.VUE_APP_DEFAULTH_LATITUDE;
    const longitude = process.env.VUE_APP_DEFAULTH_LONGITUDE;
    let { data } = await Axios.get(`https://api.openweathermap.org/data/2.5/weather?units=metric&lat=${latitude}&lon=${longitude}&appid=${appid}`);
    commit('SET_WEATHER', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
