import Axios from 'axios';

const state = {
  data: [],
};

const getters = {
  GET_TYPES: state => state.data,
};

const mutations = {
  SET_TYPES: (state, payload) => {
    state.data = payload.data;
  },
};

const actions = {
  GET_TYPES_FROM_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    const { data } = await Axios.get(`/api/types${string}`);
    context.commit('SET_TYPES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
