const state = {
  data: [
    {
      slug: 'zheleznovodsk',
      declension: {
        nominative: 'Умный Железноводск',
        genitive: 'Умного Железноводска',
        dative: 'Умному Железноводску',
        accusative: 'Умный Железноводск',
        instrumental: 'Умным Железноводском',
        prepositional: 'Умном Железноводске',
      }
    }, {
      slug: 'stavropol',
      declension: {
        nominative: 'Умный Ставрополь',
        genitive: 'Умного Ставрополя',
        dative: 'Умному Ставрополю',
        accusative: 'Умный Ставрополь',
        instrumental: 'Умным Ставрополем',
        prepositional: 'Умном Ставрополе',
      }
    }, {
      slug: 'kislovodsk',
      declension: {
        nominative: 'Умный Кисловодск',
        genitive: 'Умного Кисловодска',
        dative: 'Умному Кисловодску',
        accusative: 'Умный Кисловодск',
        instrumental: 'Умным Кисловодском',
        prepositional: 'Умном Кисловодске',
      }
    }, {
      slug: 'essentuki',
      declension: {
        nominative: 'Умные Ессентуки',
        genitive: 'Умных Ессентуках',
        dative: 'Умному Ессентуку',
        accusative: 'Умные Ессентуки',
        instrumental: 'Умными Ессентуками',
        prepositional: 'Умных Ессентуках',
      }
    }, {
      slug: 'pyatigorsk',
      declension: {
        nominative: 'Умный Пятигорск',
        genitive: 'Умного Пятигорска',
        dative: 'Умному Пятигорску',
        accusative: 'Умныей Пятигорск',
        instrumental: 'Умным Пятигорском',
        prepositional: 'Умном Пятигорске',
      }
    }, {
      slug: 'nevinnomyssk',
      declension: {
        nominative: 'Умный Невинномыск',
        genitive: 'Умных Невинномыска',
        dative: 'Умному Невинномыску',
        accusative: 'Умный Невинномыск',
        instrumental: 'Умным Невинномыском',
        prepositional: 'Умном Невинномыскк',
      }
    }, {
      slug: 'georgievsk',
      declension: {
        nominative: 'Умный Георгиевск',
        genitive: 'Умного Георгиевска',
        dative: 'Умному Георгиевску',
        accusative: 'Умный Георгиевск',
        instrumental: 'Умным Георгиевск',
        prepositional: 'Умном Георгиевске',
      }
    },{
      slug: 'lermontov',
      declension: {
        nominative: 'Умный Лермонтов',
        genitive: 'Умного Лермонтова',
        dative: 'Умному Лермонтову',
        accusative: 'Умный Лермонтов',
        instrumental: 'Умным Лермонтовом',
        prepositional: 'Умном Лермонтове',
      }
    },{
      slug: 'minvody',
      declension: {
        nominative: 'Умные Минеральные Воды',
        genitive: 'Умные Минеральные Воды',
        dative: 'Умным Минеральным Водам',
        accusative: 'Умные Минеральные Воды',
        instrumental: 'Умные Минеральные Воды',
        prepositional: 'Умных Минеральных Водах',
      }
    },
  ]
};

const getters = {
  GET_DECLENSION: state => state.data
};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
