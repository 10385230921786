import Axios from 'axios';
import i18n from "../i18n";

const state = {
  news: {}
};

const getters = {
  GET_NEW: state => state.news
};

const mutations = {
  SET_NEW: (state, payload) => state.news = payload
};

const actions = {
  GET_NEW_FROM_SERVER: async (context, payload) => {
    let { data } = await Axios.get(`/api/news/${payload}?lang=${i18n.locale}`);
    context.commit('SET_NEW', data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
