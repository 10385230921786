import Axios from 'axios';

const state = {
  single: false,
  agency: null,
  agencies: [],
};
const getters = {
  GET_OPERATORS: state => state,
};
const mutations = {
  CLEAR_AGENCY: state => state.single = false,
  SET_AGENCY_TO_OPERATORS: (state, payload) => {
    state.agency = payload;
    state.single = true;
  },
  SET_AGENCIES_TO_OPERATORS: (state, payload) => {
    state.agencies = payload.data;
  },
};
const actions = {
  GET_AGENCIES_FROM_SERVER_TO_OPERATORS_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/agencies${string}&resolution=medium`);
    context.commit('SET_AGENCIES_TO_OPERATORS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
