import Axios from 'axios';
import i18n from "../i18n";

const state = {
  event: {}
};

const getters = {
  GET_EVENT: state => state.event
};

const mutations = {
  SET_EVENT: (state, payload) => state.event = payload
};

const actions = {
  GET_EVENT_FROM_SERVER: async (context, payload) => {
    let { data } = await Axios.get(`/api/events/${payload}?lang=${i18n.locale}`);
    context.commit('SET_EVENT', data.data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
