export default {
  inserted: (el, binding) => {
    const func = (event) => {
      if (binding.value(event, el)) {
        window.removeEventListener('scroll', func);
      }
    };
    window.addEventListener('scroll', func);
  },
};
