import Axios from 'axios';

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_PANELS: state => state
};

const mutations = {
  SET_PANELS: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  }
};

const actions = {
  GET_PANELS_FROM_SERVER: async (context) => {
    const { data } = await Axios.get('/api/panels?count=100500');
    context.commit('SET_PANELS', data);
  },
  GET_PANELS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    const { data } = await Axios.get(`/api/panels${string}`);
    context.commit('SET_PANELS', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
