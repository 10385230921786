const state = {
  width: '',
  height: '',
  mobile: null,
  tablet: null,
  laptop: null,
  windowScroll: 0
};

const getters = {
  GET_VIEWPORT: state => state
};

const mutations = {
  SET_VIEWPORT: state => {
    state.width = document.documentElement.clientWidth;
    state.height = document.documentElement.clientHeight;
    if (document.documentElement.clientWidth < 768) {
      state.mobile = true;
      state.tablet = false;
      state.laptop = false;
    } else if (document.documentElement.clientWidth >= 768
      && document.documentElement.clientWidth < 1110) {
      state.mobile = false;
      state.tablet = true;
      state.laptop = false;
    } else if (document.documentElement.clientWidth >= 1110) {
      state.mobile = false;
      state.tablet = false;
      state.laptop = true;
    }
  },
  SET_WINDOW_SCROLL: state => {
    state.windowScroll = window.scrollY;
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
