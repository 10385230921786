import i18n from './i18n';

export default {
  $postAddExcursion(excursion, formData, config) {
    this.$redirectIfGuest();
    this.$http.post(`/api/trips${excursion}`, formData, config)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddExcursion',
          horizontal: 'right',
        });
      });
  },
  $putAddExcursion(excursion, formData, config, id) {
    this.$redirectIfGuest();
    this.$http.put(`/api/trips/${id}/${excursion}`, formData, config)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddExcursion',
          horizontal: 'right',
        });
      });
  },
  $postAddAgencies(agencies) {
    this.$redirectIfGuest();
    this.$http.post(`/api/agencies${agencies}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER'));
  },
  $putAddAgencies(agencies, id) {
    this.$redirectIfGuest();
    this.$http.put(`/api/agencies/${id}/${agencies}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER'));
  },
  $postAddObject(place) {
    this.$redirectIfGuest();
    this.$http.post(`/api/places${place}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      });
  },
  $putAddObject(place, id) {
    this.$redirectIfGuest();
    this.$http.put(`/api/places/${id}/${place}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_PLACES_FROM_SERVER'));
  },
  $putAddGuide(id, guide) {
    this.$redirectIfGuest();
    this.$http.put(`/api/guides/${id}/${guide}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_GUIDE_FROM_SERVER'));
  },
  $postAddGuide(guide) {
    this.$redirectIfGuest();
    this.$http.post(`/api/guides${guide}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddObject',
          horizontal: 'right',
        });
      })
      .then(() => this.$store.dispatch('GET_MY_GUIDE_FROM_SERVER'));
  },
  $postAddFact(fact) {
    this.$redirectIfGuest();
    this.$http.post(`/api/content/fact${fact}`)
      .then(() => {
        this.$store.commit('OPEN_POPUP', {
          width: this.$laptop ? 800 : '100%',
          height: '100vh',
          type: 'PopupSuccessfulAddFact',
          horizontal: 'right',
        });
      });
  },
  $openPopupAddObject(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$laptop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddObject',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddGuide(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$laptop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddGuide',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddAgencies() {
    window.location = process.env.VUE_APP_DEFAULTH_ROOT_ACCOUNT_ON_KEYCLOAK;
    // $openPopupAddAgencies(additions) {
    // this.$redirectIfGuest();
    // this.$store.commit('CLOSE_POPUP');
    // this.$store.commit('OPEN_POPUP', {
    //   width: this.$laptop ? 800 : '100%',
    //   height: '100vh',
    //   type: 'PopupAddAgencies',
    //   horizontal: 'right',
    //   additions,
    // });
  },
  $openPopupAddFact() {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$laptop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddFact',
      horizontal: 'right',
    });
  },
  $openPopupAddComment(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$laptop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddComment',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupAddExcursion(additions) {
    this.$redirectIfGuest();
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$laptop ? 800 : '100%',
      height: '100vh',
      type: 'PopupAddExcursion',
      horizontal: 'right',
      additions,
    });
  },
  $openPopupStories(additions) {
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: '100%',
      height: '100vh',
      type: 'PopupStories',
      horizontal: 'center',
      vertical: 'center',
      additions,
    });
  },
  $openPopupChooseMunicipality() {
    this.$store.commit('OPEN_POPUP', {
      width: this.$laptop ? 730 : '100%',
      height: this.$laptop ? 510 : '100vh',
      type: 'ChooseMunicipality',
    });
  },
  $openPopupMobileMenu() {
    let width;
    if(this.$laptop) width = '350px'
    if(this.$tablet) width = '350px'
    if(this.$mobile) width = '100%'
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width,
      height: '100vh',
      type: 'AccountMenu',
      horizontal: 'right',
      vertical: 'top',
    });
  },
  $handleScrollTo(selector, indent) {
    let top = document?.querySelector(selector)?.offsetTop;
    window.scrollTo({
      top: top -= indent || 0,
      behavior: 'smooth',
    });
  },
  $redirectIfGuest() {
    if (!this.$user.logged) {
      document.location.href = `/login/keycloak?latest_url=${window.location.host}${this.$route.fullPath}`;
    }
  },
  $getTripsByCity(cityId) {
    this.$store.dispatch('GET_TRIPS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: cityId,
        count: 100500,
      },
    });
  },
  $getNewsByCity(cityId) {
    this.$store.dispatch('GET_NEWS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: cityId,
        count: 100500,
      },
    });
  },
  $getFactsByCity(cityId) {
    this.$store.dispatch('GET_FACTS_FROM_SERVER_WITH_PARAMS', {
      clear: true,
      params: {
        city_id: cityId,
        count: 100500,
      },
    });
  },
  $openPopupImage(additions) {
    this.$store.commit('CLOSE_POPUP');
    this.$store.commit('OPEN_POPUP', {
      width: this.$viewport.width,
      height: '100vh',
      type: 'PopupImage',
      vertical: 'center',
      horizontal: 'center',
      additions,
    });
  },
  $type(type) {
    let string = '';
    if (i18n.locale === 'ru') {
      if (type === 'place') string = 'Объект';
      if (type === 'event') string = 'Событие';
      if (type === 'fact') string = 'Факт';
      if (type === 'news') string = 'Новость';
      if (type === 'excursion') string = 'Экскурсия';
      if (type === 'route') string = 'Маршрут';
      if (type === 'terrenkur') string = 'Терренкур';
    } else {
      if (type === 'place') string = 'Object';
      if (type === 'event') string = 'Event';
      if (type === 'fact') string = 'Fact';
      if (type === 'news') string = 'News';
      if (type === 'excursion') string = 'Excursion';
      if (type === 'route') string = 'Route';
      if (type === 'terrenkur') string = 'Terrenkur';
    }
    return string;
  },
  $shuffle(array) {
    return array.map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => a[1]);
  },
  $globalCitySelected() {
    return (this.$city.id) ? this.$city.id : '';
  },
  $randomKey(key) {
    return `${Math.random()
      .toString(16)
      .substr(2, 8)}-${key}`;
  },
  $stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  },
};
