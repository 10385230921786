const state = {
  playlist: [],
  autoPlay: false,
  loop: false,
  audio: undefined,
  currentAudio: '',
  currentSeconds: 0,
  durationSeconds: 0,
  innerLoop: false,
  loaded: false,
  playing: false,
  previousVolume: 35,
  showVolume: false,
  volume: 100,
};
const getters = {
  GET_PLAYER: state => state
};
const mutations = {
  SET_PLAYLIST: (state, payload) => state.playlist = payload,
  SET_AUDIO: (state, payload) => state.audio = payload,
  SET_CURRENT_AUDIO: (state, payload) => state.currentAudio = payload,
  PLAY: state => state.playing = true,
  PAUSE: state => state.playing = false,
  STOP: state => {
    state.playing = false;
    state.currentSeconds = 0;
  }
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
