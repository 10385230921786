import Axios from 'axios';

const state = {
    data: [],
    locale: {}
};

const getters = {
    GET_LOCALES: state => state.data,
    GET_LOCALE: state => state.locale,
};

const mutations = {
    SET_LOCALES: (state, payload) => {
        let newArr = payload.data;

        newArr.forEach(item => {
            switch (item.locale) {
                case 'en':
                    item.translations = '/locales/en.json';
                    item.base = '/en';
                    break;
                case 'ru':
                    item.translations = '/locales/ru.json';
                    item.base = '';
                    break;
                default:
                    break;
            }
        });

        state.data = [...newArr];
    },
    SET_LOCALE: (state, payload) => {
        state.locale = payload
    }
};

const actions = {
    GET_LOCALES_FROM_SERVER: async context => {
        let { data } = await Axios.get(`/api/languages`);
        context.commit('SET_LOCALES', data);
    },
    SET_LOCALE({ commit }, payload) {
        commit('SET_LOCALE', payload);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
