import Axios from 'axios';

const state = {
  list: [],
  sliders: [],
};

const getters = {
  GET_PROMO: state => state,
};

const mutations = {
  SET_PROMO_SLIDERS: (state, payload) => state.sliders = payload,
  SET_PROMO_LIST: (state, payload) => state.list = payload,
};

const actions = {
  GET_PROMO_SLIDERS_FROM_SERVER: async (context, payload) => {
    if (payload.clear) {
      state.list = [];
      state.sliders = [];
    }
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/sliders${string}`);
    context.commit('SET_PROMO_LIST', data.data);
    context.dispatch('GET_PROMO_SLIDER_BY_ID_FROM_SERVER', {
      data,
      string,
    });
  },
  GET_PROMO_SLIDER_BY_ID_FROM_SERVER: async (context, { data, string }) => {
    let array = data.data.map(slider => Axios.get(`/api/sliders/${slider.id}${string}&resolution=medium`));
    let list = await Promise.all(array)
      .then(resolve => resolve.map(item => item.data.data));
    context.commit('SET_PROMO_SLIDERS', list);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
