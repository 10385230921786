const state = {
  type: '',
  exceptions: [{
    exception: 'excursions',
    truly: 'trips',
  }],
};

const getters = {
  GET_PAGE: state => state
};
const mutations = {
  SET_TYPE_PAGE: (state, payload) => {
    const type = payload.path.split('/')[1];
    state.type = state.exceptions.find(item => item.exception === type)?.truly || type;
  }
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
