import Axios from 'axios';
import i18n from "../i18n";

const state = {
  data: [],
};

const getters = {
  GET_BADGES: state => state.data,
};

const mutations = {
  SET_BADGES: (state, payload) => {
    state.data = payload.data;
  },
};

const actions = {
  GET_BADGES_FROM_SERVER: async (context) => {
    const { data } = await Axios.get(`/api/badges?count=100500&lang=${i18n.locale}`);
    context.commit('SET_BADGES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
