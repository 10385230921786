import Vue from 'vue';
import Vuex from 'vuex';
import agency from './agency';
import articles from './articles';
import badges from './badges';
import cities from './cities';
import city from './city';
import comments from './comments';
import content from './content';
import contents from './contents';
import event from './event';
import events from './events';
import excursions from './agencies';
import facts from './facts';
import guide from './guide';
import guides from './guides';
import header from './header';
import nearPlace from './nearPlace';
import news from './news';
import operators from './operators';
import page from './page';
import panels from './panels';
import place from './place';
import placeTypeCategories from './placeTypeCategories';
import places from './places';
import player from './player';
import popup from './popup';
import promo from './promo';
import routes from './routes';
import search from './search';
import singleNews from './new';
import stories from './stories';
import theme from './theme';
import trip from './trip';
import tripSubtypes from './tripSubtypes';
import trips from './trips';
import tripsExcursions from './excursions';
import types from './types';
import user from './user';
import viewport from './viewport';
import weather from './weather';
import heroSlider from './heroSlider';
import meta from './meta';
import declension from './declension';
import locales from './locales';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    agency,
    articles,
    badges,
    cities,
    city,
    comments,
    content,
    contents,
    event,
    events,
    excursions,
    facts,
    guide,
    guides,
    header,
    nearPlace,
    news,
    operators,
    page,
    panels,
    place,
    placeTypeCategories,
    places,
    player,
    popup,
    promo,
    routes,
    search,
    singleNews,
    stories,
    theme,
    trip,
    tripSubtypes,
    trips,
    tripsExcursions,
    types,
    user,
    viewport,
    weather,
    heroSlider,
    meta,
    declension,
    locales
  }
});
