import Axios from 'axios';

const state = {
  data: '',
  links: '',
};

const getters = {
  GET_RESPONSE: state => state
};

const mutations = {
  CLEAR_SEARCH: state => {
    state.data = null;
    state.links = null;
  },
  SET_RESPONSE: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
  },
  SET_NEXT_RESPONSE: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
  },
};

const actions = {
  REQUEST_SEARCH_FROM_SERVER: async (context, payload) => {
    if (payload.clear) {
      state.data = [];
      state.links = [];
    }
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/search${string}`);
    context.commit('SET_RESPONSE', data);
  },
  GET_NEXT_REQUEST_SEARCH_FROM_SERVER: async (context, payload) => {
    if (payload.clear) {
      state.data = [];
      state.links = [];
    }
    let { data } = await Axios.get(payload.params.page);
    context.commit('SET_NEXT_RESPONSE', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
