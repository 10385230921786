import Axios from 'axios';

const state = {
  data: [],
  links: {},
  meta: {}
};

const getters = {
  GET_EXCURSIONS: state => state,
};

const mutations = {
  UPDATE_EXCURSIONS: (state, payload) => {
    let newArray = [];
    state.data.forEach(item => {
      if (item.id === payload.id) {
        newArray = [...newArray, payload];
      } else {
        newArray = [...newArray, item];
      }
    });
    state.data = newArray;
  },
  SET_EXCURSIONS: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
    state.meta = payload.meta;
  },
};

const actions = {
  GET_EXCURSIONS_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/trips?type=excursion`);
    context.commit('SET_EXCURSIONS', data);
  },
  GET_EXCURSIONS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/trips${string}`);
    context.commit('SET_EXCURSIONS', data);
  },
  GET_NEXT_EXCURSIONS_FROM_SERVER: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      string += `&${key}=${payload.params[key]}`;
    }
    let { data } = await Axios.get(`/api/trips${string}`);
    context.commit('SET_EXCURSIONS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
