import Axios from 'axios';

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_HERO_SLIDER: state => state
};

const mutations = {
  SET_HERO_SLIDER: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  }
};

const actions = {
  GET_HERO_SLIDER_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/heroslider${string}&resolution=extra`);
    context.commit('SET_HERO_SLIDER', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
