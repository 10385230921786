import Axios from 'axios';
import i18n from "../i18n";

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_PLACES: state => state,
};

const mutations = {
  SET_PLACES: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
    state.meta = payload.meta;
  },
};

const actions = {
  GET_ALL_PLACES_FROM_SERVER: async (context) => {
    const { data } = await Axios.get(`/api/places?count=100500&lang=${i18n.locale}`);
    context.commit('SET_PLACES', data);
  },
  GET_PLACES_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/places${string}`);
    context.commit('SET_PLACES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
