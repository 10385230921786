import Vue from 'vue';
import VueRouter from 'vue-router';
import Axios from 'axios';
import i18n from '../i18n';
import store from '../store/index';

const LogoutView = () => import('../views/LogoutView.vue');
const AccountAgenciesView = () => import('../views/AccountAgenciesView.vue');
const AccountAgencyView = () => import('../views/AccountAgencyView.vue');
const AccountBecomeGuideView = () => import('../views/AccountBecomeGuideView.vue');
const AccountExcursionsView = () => import('../views/AccountExcursionsView.vue');
const AccountFactsView = () => import('../views/AccountFactsView.vue');
const AccountFavoritesView = () => import('../views/AccountFavoritesView.vue');
const AccountPlacesView = () => import('../views/AccountPlacesView.vue');
const AccountSettingsView = () => import('../views/AccountSettingsView.vue');
const AccountView = () => import('../views/AccountView.vue');
const BusView = () => import('../views/BusView.vue');
const CityView = () => import('../views/CityView.vue');
const ErrorView = () => import('../views/ErrorView.vue');
const EventView = () => import('../views/EventView.vue');
const EventsView = () => import('../views/EventsView.vue');
const ExcursionView = () => import('../views/ExcursionView.vue');
const ExcursionsView = () => import('../views/ExcursionsView.vue');
const HomeView = () => import('../views/HomeView.vue');
const InfoView = () => import('../views/InfoView.vue');
const InteractiveMapView = () => import('../views/InteractiveMapView.vue');
const NewView = () => import('../views/NewView.vue');
const NewsView = () => import('../views/NewsView.vue');
const PlaceView = () => import('../views/PlaceView.vue');
const PlacesView = () => import('../views/PlacesView.vue');
const StoriesView = () => import('../views/StoriesView.vue');
const StoryView = () => import('../views/StoryView.vue');
const TestView = () => import('../views/TestView.vue');
const TransportView = () => import('../views/TransportView.vue');
const TripView = () => import('../views/TripView.vue');
const TripsView = () => import('../views/TripsView.vue');

Vue.use(VueRouter);

const SUPPORTED_LOCALES = [{
  code: 'en',
  base: '/en',
  translations: '/locales/en.json',
}, {
  code: 'ru',
  base: '',
  translations: '/locales/ru.json',
}];

// Creates regex (en|fr)
function getLocaleRegex() {
  let reg = '';
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
}

// Returns locale configuration
function getLocale(locale = 'ru') {
  return SUPPORTED_LOCALES.find((loc) => loc.code === locale);
}

const routes = [{
  path: `/:lang${getLocaleRegex()}?`,
  component: {
    render(c) { return c('router-view'); },
  },
  beforeEnter(to, from, next) {
    const locale = getLocale(to.params.lang);
    store.dispatch('SET_LOCALE', locale);
    Axios.get(locale.translations).then((res) => {
      i18n.setLocaleMessage(locale.code, res.data || {});
    }).catch(() => {}).finally(() => {
      next();
    });
  },
  children: [
    {
      path: '',
      props: (router) => ({ api_token: router.query.api_token }),
      name: 'HomeView',
      component: HomeView,
    }, {
      path: 'info',
      name: 'InfoView',
      component: InfoView,
    }, {
      path: 'account',
      name: 'AccountView',
      component: AccountView,
    }, {
      path: 'account/settings',
      name: 'AccountSettingsView',
      component: AccountSettingsView,
    }, {
      path: 'account/favorites',
      name: 'AccountFavoritesView',
      component: AccountFavoritesView,
    }, {
      path: 'account/excursions',
      name: 'AccountExcursionsView',
      component: AccountExcursionsView,
    }, {
      path: 'account/places',
      name: 'AccountPlacesView',
      component: AccountPlacesView,
    }, {
      path: 'account/facts',
      name: 'AccountFactsView',
      component: AccountFactsView,
    }, {
      path: 'account/agencies',
      name: 'AccountAgenciesView',
      component: AccountAgenciesView,
    }, {
      path: 'account/agency/:id',
      name: 'AccountAgencyView',
      component: AccountAgencyView,
    }, {
      path: 'account/become-a-guide',
      name: 'AccountBecomeGuideView',
      component: AccountBecomeGuideView,
    }, {
      path: 'trips',
      name: 'TripsView',
      component: TripsView,
    }, {
      path: 'trips/:id',
      name: 'TripView',
      component: TripView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_TRIP_FROM_SERVER', to.params.id)
          .then(() => next())
          .catch((error) => {
            if (error.response.status === 404) next('error');
          });
      },
    }, {
      path: 'places',
      name: 'PlacesView',
      component: PlacesView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_PLACE_TYPE_CATEGORIES_FROM_SERVER')
          .then(() => next());
      },
    }, {
      path: 'places/:id',
      name: 'PlaceView',
      component: PlaceView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_PLACE_FROM_SERVER_WITH_PARAMS', to.params.id)
          .then(() => next())
          .catch((error) => {
            if (error.response.status === 404) next('error');
          });
      },
    }, {
      path: 'municipality',
      name: 'CityView',
      component: CityView,
    }, {
      path: 'excursions',
      name: 'ExcursionsView',
      component: ExcursionsView,
    }, {
      path: 'excursions/:id',
      name: 'ExcursionView',
      component: ExcursionView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_TRIP_FROM_SERVER', to.params.id)
          .then(() => next())
          .catch((error) => {
            if (error.response.status === 404) next('error');
          });
      },
    }, {
      path: 'stories',
      name: 'StoriesView',
      component: StoriesView,
    }, {
      path: 'stories/:id',
      name: 'StoryView',
      component: StoryView,
    }, {
      path: 'news',
      name: 'NewsView',
      component: NewsView,
    }, {
      path: 'news/:id',
      name: 'NewView',
      component: NewView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_NEW_FROM_SERVER', to.params.id)
          .then(() => next())
          .catch((error) => {
            if (error.response.status === 404) next('error');
          });
      },
    }, {
      path: 'events',
      name: 'EventsView',
      component: EventsView,
    }, {
      path: 'events/:id',
      name: 'EventView',
      component: EventView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_EVENT_FROM_SERVER', to.params.id)
          .then(() => next())
          .catch((error) => {
            if (error.response.status === 404) next('error');
          });
      },
    }, {
      path: 'interactive-map',
      name: 'InteractiveMapView',
      component: InteractiveMapView,
      beforeEnter: (to, from, next) => {
        store.dispatch('GET_PLACES_FROM_SERVER_WITH_PARAMS', {
          clear: true,
          params: {
            show: 'id,title,images,address,location,audios,type,type_id',
            resolution: 'medium',
            lang: i18n.locale,
            count: 100500,
            city_id: store.getters.GET_CITY.id,
          },
        })
          .then(() => next());
      },
    }, {
      path: 'transport',
      name: 'TransportView',
      component: TransportView,
    }, {
      path: 'test',
      name: 'TestView',
      component: TestView,
    }, {
      path: 'transport-map',
      name: 'BusView',
      component: BusView,
    }, {
      path: '*',
      name: 'ErrorView',
      component: ErrorView,
    }, {
      path: 'logout',
      component: LogoutView,
    },
  ],
}];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const subdomain = window?.location?.host?.split('.').length > 2
    ? window.location.host.split('.')[0]
    : false;
  store.commit('SET_VIEWPORT');
  store.commit('SET_TYPE_PAGE', to);

  const locale = getLocale(to.params.lang);
  if (!locale) {
    locale.code = 'ru';
  }
  localStorage.setItem('locale', locale);
  i18n.locale = locale.code;

  if (store.getters.GET_CITIES.data.length === 0) {
    store.dispatch('GET_CITIES_FROM_SERVER')
      .then(() => {
        store.dispatch('GET_CITY_BY_SLUG', {
          clear: true,
          params: {
            lang: i18n.locale,
            slug: store.getters.GET_CITIES.data.some((item) => item.slug === subdomain)
              ? subdomain
              : process.env.VUE_APP_DEFAULTH_CITY_SLUG,
          },
        })
          .then(() => {
            store.dispatch('GET_PROMO_SLIDERS_FROM_SERVER', {
              clear: true,
              params: {
                lang: i18n.locale,
                count: 100500,
                city_id: store.getters.GET_CITY.id,
              },
            });
            store.dispatch('GET_TYPES_FROM_SERVER', {
              clear: true,
              params: {
                lang: i18n.locale,
                count: 100500,
                city_id: store.getters.GET_CITY.id,
              },
            });
          })
          .then(() => next());
      });
  } else {
    next();
  }
});

export default router;
