import Axios from 'axios';
import logger from 'vuex/dist/logger';

const state = {
  routes: [],
  gps: [],
  stations: [],
  stationsByRoute: [],
  vehicles: [],
  stationForecast: [],
  stationsOnRoute: [],
  schedule: []
};

const getters = {
  GET_ROUTES: state => state.routes,
  GET_GPS: state => state.gps,
  GET_STATIONS: state => state.stations,
  GET_VEHICLES: state => state.vehicles,
  GET_STATION_FORECAST: state => state.stationForecast,
  GET_STATIONS_ON_ROUTE: state => state.stationsOnRoute,
  GET_SCHEDULE: state => state.schedule,
};

const mutations = {
  SET_ROUTES: (state, payload) => {
    state.routes = payload
  },
  SET_GPS: (state, payload) => {
    state.gps = payload
  },
  SET_STATIONS: (state, payload) => {
    state.stations = payload
  },
  SET_STATIONS_BY_ROUTE: (state, payload) => {
    state.stationsByRoute = payload
  },
  SET_VEHICLES: (state, payload) => {
    state.vehicles = payload
  },
  SET_STATION_FORECAST: (state, payload) => {
    state.stationForecast = null;
    state.stationForecast = payload;
  },
  SET_STATIONS_ON_ROUTE: (state, payload) => {
    const tests = state.schedule.filter((x) => payload.some((t) => t.id === x.id));
    state.stationsOnRoute = tests;
  },
  SET_SCHEDULE: (state, payload) => {
    const arr1 = payload;
    const arr2 = state.stations;
    // Мержим список остановок и расписаний!
    const merged = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr1.length; i++) {
      merged.push({
        ...arr1[i],
        // eslint-disable-next-line eqeqeq
        ...(arr2.find((itmInner) => itmInner.id == arr1[i].id)),
      });
    }
    // В свойстве routes необходимо выводить только те расписания внутри которых
    // есть свойство showSchedule - здесь фильтруем на предмет наличия этого дела!
    let filteredArray = merged.map((el) => {
      let objectToPushFiltered = {}
      for (const [key, value] of Object.entries(el.routes)) {
        if (value.hasOwnProperty('showSchedule')) {
          objectToPushFiltered[key] = value
        }
      }
      return {
        id: el.id,
        name: el.name,
        routes: objectToPushFiltered,
        descr: el.descr,
        lat: el.lat,
        lng: el.lng,
        type: el.type
      }
    });
    state.schedule = filteredArray;
  }
};

const actions = {
  REQUEST_ROUTES: async (context, payload) => {
    let {data} = await Axios.get(`/api/bus62/api8/getRoutes.php?city=${payload}`);
    context.commit('SET_ROUTES', data);
  },
  REQUEST_STATIONS: async (context, payload) => {
    let {data} = await Axios.get(`/api/bus62/api8/getStations.php?city=${payload}`);
    context.commit('SET_STATIONS', data);
  },
  REQUEST_GPS_BY_ROUTE: async (context, payload) => {
    let {data} = await Axios.get(`/api/bus62/api8/getRouteNodes.php?city=zheleznovodsk&type=0&rid=${payload}`);
    context.commit('SET_GPS', data);
  },
  REQUEST_VEHICLES: async (context, payload) => {
    let {data} = await Axios.get(`/api/bus62/api8/getVehiclesMarkers.php?rids=15-0,16-0,18-0,17-0,20-0,21-0,19-0,22-0,29-0,30-0,24-0,23-0,26-0,25-0,27-0,28-0&lat0=0&lng0=0&lat1=90&lng1=180&curk=0&city=${payload}&info=12345&_=1564767444028`);
    let correct = data.anims;
    context.commit('SET_VEHICLES', correct);
  },
  REQUEST_STATION_FORECAST: async (context, payload) => {
    let {data} = await Axios.get(`/api/bus62/api8/getStationForecasts.php?sid=${payload}&type=0&city=zheleznovodsk&info=12345&_=1564767444028`);
    context.commit('SET_STATION_FORECAST', data)
  },
  REQUEST_STATIONS_ON_ROUTE: async (context, payload) => {
    let {data} = await Axios.get(`/api/bus62/api8/getRouteStations.php?city=zheleznovodsk&rid=${payload}&type=0`)
    context.commit('SET_STATIONS_ON_ROUTE', data)
  },
  REQUEST_SCHEDULE: async (context) => {
    let {data} = await Axios.get('https://smart-fe26.ru/transportProxy.php?url=schedule');
    context.commit('SET_SCHEDULE', data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
