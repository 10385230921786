const state = {
  data: [],
};

const getters = {
  GET_META: state => state.data
};

const mutations = {
  SET_META: (state, payload) => {
    state.data = [...state.data, payload];
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
}
