import Axios from 'axios';
import i18n from "../i18n";

const state = {
  data: [],
};

const getters = {
  GET_GUIDES: state => state.data,
};

const mutations = {
  SET_GUIDES: (state, payload) => state.data = payload.data,
};

const actions = {
  GET_GUIDES_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/guides?lang=${i18n.locale}`);
    context.commit('SET_GUIDES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
