const state = {
  show: false,
  width: 0,
  height: 'auto',
  horizontal: 'center',
  vertical: 'center',
  type: '',
  additions: {},
  default: {
    width: 400,
    height: 400,
    horizontal: 'center',
    vertical: 'center',
    type: '',
    additions: {},
  },
};
const getters = {
  GET_POPUP: state => state,
};
const mutations = {
  OPEN_POPUP: (state, payload) => {
    state.show = true;
    Object.keys(payload)
      .forEach((key) => {
        state[key] = payload[key];
      });
  },
  CLOSE_POPUP: (state) => {
    state.show = false;
    Object.keys(state.default)
      .forEach((key) => {
        state[key] = state.default[key];
      });
  },
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
