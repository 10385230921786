import Axios from 'axios';
import i18n from "../i18n";

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_TRIP_SUB_TYPES: state => state.data,
};

const mutations = {
  SET_TRIP_SUB_TYPES: (state, payload) => {
    state.data = payload.data;
    state.links = payload.links;
    state.meta = payload.meta;
  },
};

const actions = {
  GET_TRIP_SUB_TYPES_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/trip_subtypes?lang=${i18n.locale}`);
    context.commit('SET_TRIP_SUB_TYPES', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
