import Axios from 'axios';
import i18n from "../i18n";

const state = {
  data: [],
};

const getters = {
  GET_PLACE_TYPE_CATEGORIES: state => state.data,
};

const mutations = {
  SET_PLACE_TYPE_CATEGORIES: (state, payload) => {
    state.data = payload;
  },
};

const actions = {
  GET_PLACE_TYPE_CATEGORIES_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/place_type_categories?count=100500&city_id=${context.getters.GET_CITY.id}&lang=${i18n.locale}&resolution=medium`);
    context.dispatch('GET_PLACES_BY_TYPE_FROM_SERVER', data.data);
  },
  GET_PLACES_BY_TYPE_FROM_SERVER: (context, categories) => {
    categories.forEach(async category => {
      category.selected_type = null;
      category.sortType = 'created_at';
      let types_ids = '';
      if (category?.place_types?.length > 0) {
        category.place_types.forEach((place_type, index) => {
          types_ids += index === 0 ? place_type.id : `,${place_type.id}`;
        });
        category.types_ids = types_ids;
        category.places = await Axios
          .get(`/api/places?city_id=${context.getters.GET_CITY.id}&type_id=${types_ids}&sort=${category.sortType}&count=100500&lang=${i18n.locale}&resolution=medium`)
          .then(resolve => resolve.data.data);
        category.place_types = [{
          id: null,
          title: i18n.locale === 'ru' ? 'Все' : 'All'
        }, ...category.place_types];
      } else {
        category.places = [];
      }
    });
    context.commit('SET_PLACE_TYPE_CATEGORIES', categories);
  },
  SET_ID_TYPE_IN_PLACE_TYPE_CATEGORIES: async (context, { categories, categoryId, typeId }) => {
    let { data } = await Axios
      .get(`/api/places?city_id=${context.getters.GET_CITY.id}&resolution=medium&type_id=${typeId !== null ? typeId : categories
        .find(item => item.id === categoryId).types_ids}&resolution=medium&sort=${categories
        .find(item => item.id === categoryId).sortType}&resolution=medium&count=100500&lang=${i18n.locale}`);
    categories.forEach(category => {
      if (category.id === categoryId) {
        category.places = data.data;
        category.selected_type = typeId;
      }
    });
    context.commit('SET_PLACE_TYPE_CATEGORIES', categories);
  },
  SET_SORT_TYPE_IN_PLACE_TYPE_CATEGORIES: async (context, { categories, categoryId, sortType }) => {
    let { data } = await Axios
      .get(`/api/places?city_id=${context.getters.GET_CITY.id}&resolution=medium&type_id=${categories
        .find(item => item.id === categoryId).selected_type}&resolution=medium&sort=${sortType}&count=100500&lang=${i18n.locale}`);
    categories.forEach(category => {
      if (category.id === categoryId) {
        category.places = data.data;
        category.sortType = sortType;
      }
    });
    context.commit('SET_PLACE_TYPE_CATEGORIES', categories);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
