import Axios from 'axios';

const state = {
  data: [],
  links: {},
  meta: {},
};

const getters = {
  GET_NEWS: state => state,
};

const mutations = {
  SET_NEWS: (state, payload) => {
    state.data = [...state.data, ...payload.data];
    state.links = payload.links;
    state.meta = payload.meta;
  }
};

const actions = {
  GET_NEWS_FROM_SERVER: async (context) => {
    let { data } = await Axios.get(`/api/news`);
    context.commit('SET_NEWS', data);
  },

  GET_NEWS_FROM_SERVER_WITH_PARAMS: async (context, payload) => {
    if (payload.clear) state.data = [];
    let string = '?';
    for (let key in payload.params) {
      if (!!payload.params[key]) {
        string += `&${key}=${payload.params[key]}`;
      }
    }
    let { data } = await Axios.get(`/api/news${string}`);
    context.commit('SET_NEWS', data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
