<template>
  <div id="app"
    v-scroll="handleScroll"
    v-on:resize="handleResize($event)">
    <Sprite/>
    <Header>
      <template slot="header-logotype">
        <router-link v-if="metaHeaderLogotype"
          :to="$locale.base + '/'">
          <img :style="style"
            :src="require(`@/assets/images/${metaHeaderLogotype}`)" alt/>
        </router-link>
      </template>
    </Header>
    <ResponseForSearch v-if="$search.data"/>
    <div class="content">
      <router-view/>
    </div>
    <Footer/>
    <transition name="fade">
      <Popup v-if="$popup.show"/>
    </transition>
  </div>
</template>

<style lang="scss">
@import "./assets/style/main";
</style>

<script>
export default {
  beforeCreate() {
    this.$store.commit('SET_ROOT');
    this.$store.commit('SET_COLORS');
    this.$moment.locale(this.$i18n.locale);
  },
  created() {
    if (this.$i18n.locale === 'ru') {
      const meta = require('@/assets/json/default.json');
      this.$store.commit('SET_META', meta);
      const zheleznovodsk = require('@/assets/json/zheleznovodsk.json');
      this.$store.commit('SET_META', zheleznovodsk);
    } else if (this.$i18n.locale === 'en') {
      const meta = require('@/assets/json/default_en.json');
      this.$store.commit('SET_META', meta);
      const zheleznovodsk = require('@/assets/json/zheleznovodsk_en.json');
      this.$store.commit('SET_META', zheleznovodsk);
    }

    const kislovodsk = require('@/assets/json/kislovodsk.json');
    this.$store.commit('SET_META', kislovodsk);
    const nevinnomyssk = require('@/assets/json/nevinnomyssk.json');
    this.$store.commit('SET_META', nevinnomyssk);
    const essentuki = require('@/assets/json/essentuki.json');
    this.$store.commit('SET_META', essentuki);
    const minvody = require('@/assets/json/minvody.json');
    this.$store.commit('SET_META', minvody);
    const pjatigorsk = require('@/assets/json/pjatigorsk.json');
    this.$store.commit('SET_META', pjatigorsk);
    const stavropol = require('@/assets/json/stavropol.json');
    this.$store.commit('SET_META', stavropol);
    const georgievsk = require('@/assets/json/georgievsk.json');
    this.$store.commit('SET_META', georgievsk);
    const lermontov = require('@/assets/json/lermontov.json');
    this.$store.commit('SET_META', lermontov);
    if (!this.$user.logged) this.login();
    this.$store.dispatch('GET_BADGES_FROM_SERVER');
    this.$store.dispatch('GET_TRIP_SUB_TYPES_FROM_SERVER');
    this.$store.dispatch('GET_WEATHER_FROM_SERVER');
    this.$store.dispatch('GET_LOCALES_FROM_SERVER');
  },
  computed: {
    style() {
      return {
        width: '170px',
        height: '80px',
        padding: this.$city.slug === 'minvody' ? '5px' : '18px 23px 18px 8px',
        objectFit: 'contain'
      };
    },
    meta() {
      return this?.$meta?.find(item => item.slug === this.$city.slug);
    },
    metaHeaderLogotype() {
      return this?.meta?.header?.logotype;
    },
  },
  methods: {
    login() {
      const apiToken = this.$route.query.api_token || localStorage.api_token;
      if (apiToken) {
        localStorage.setItem('api_token', apiToken);
        this.$store.commit('SET_TOKEN', apiToken);
        this.$http.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;
        setTimeout(() => { // попытка исправить баг для IOS mobile > 0.19
          this.$store.dispatch('WHOAMI');
        });
        if (this.$route.query.api_token) this.$router.replace({ query: null });
      }
    },
    handleResize() {
      this.$store.commit('SET_VIEWPORT');
    },
    handleScroll() {
      this.$store.commit('SET_WINDOW_SCROLL');
    },
  },
  mounted() {
    if (!this.$user.logged) this.login();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    $route() {
      if (!this.$user.logged) this.login();
      window.scrollTo(0, 0);
      this.$store.commit('CLOSE_POPUP');
      this.$store.commit('CLEAR_SEARCH');
      this.$store.commit('CLOSE_SEARCH_BAR');
    },
  },
};
</script>
