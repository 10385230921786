const state = {
  root: null
};
const getters = {
  GET_THEME: state => state,
};
const mutations = {
  SET_ROOT: state => {
    state.root = document.documentElement;
  },
  SET_COLORS: state => {
    state.root.style.setProperty('--main-color', process.env.VUE_APP_DEFAULTH_MAIN_COLOR);
    state['--main-color'] = process.env.VUE_APP_DEFAULTH_MAIN_COLOR;
    state.root.style.setProperty('--main-color-rgb', process.env.VUE_APP_DEFAULTH_MAIN_COLOR_RGB);
    state['--main-color-rgb'] = process.env.VUE_APP_DEFAULTH_MAIN_COLOR_RGB;
    state.root.style.setProperty('--main-background-gradient', process.env.VUE_APP_DEFAULTH_BACKGROUND_GRADIENT);
    state['--main-background-gradient'] = process.env.VUE_APP_DEFAULTH_BACKGROUND_GRADIENT;

    state.root.style.setProperty('--white', process.env.VUE_APP_DEFAULTH_WHITE);
    state['--white'] = process.env.VUE_APP_DEFAULTH_WHITE;
    state.root.style.setProperty('--black', process.env.VUE_APP_DEFAULTH_BLACK);
    state['--black'] = process.env.VUE_APP_DEFAULTH_BLACK;

    state.root.style.setProperty('--green', process.env.VUE_APP_DEFAULTH_GREEN);
    state['--green'] = process.env.VUE_APP_DEFAULTH_GREEN;
    state.root.style.setProperty('--red', process.env.VUE_APP_DEFAULTH_RED);
    state['--red'] = process.env.VUE_APP_DEFAULTH_RED;
    state.root.style.setProperty('--yellow', process.env.VUE_APP_DEFAULTH_YELLOW);
    state['--yellow'] = process.env.VUE_APP_DEFAULTH_YELLOW;
    state.root.style.setProperty('--blue', process.env.VUE_APP_DEFAULTH_BLUE);
    state['--blue'] = process.env.VUE_APP_DEFAULTH_BLUE;
  }
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
